import React from 'react';
import {Get} from '@karpeleslab/klbfw';
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();
	return (
		<textarea id="mail-text">
			{t('mail_user_name', {name:Get('_user').Profile.Display_Name} )}

			{t('mail_failed_text_01')}

			{t('mail_failed_text_02')}

			{t('mail_failed_text_03')}

			{t('mail_common_text_03')}
			{t('mail_common_text_01')}
			{t('mail_common_text_02')}

https://patraria.jp/
		</textarea>
	);
};

export default Text;