import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Grid, Button} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import bgname from '../../assets/img/bgName.png';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import ImagePaymentComplete from "../../assets/img/img_payment_complete.png";

import {rest} from "@karpeleslab/klbfw";

//import NumericInput from 'react-numeric-input';
//import { useSnackbar } from 'notistack';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	container:{
		width:"840px",
		padding:"16px",
		background:'#ffffff',
		"@media (min-width: 576px)": {
			maxWidth: "540px"
		},
		"@media (min-width: 768px)": {
			maxWidth: "720px"
		},
		"@media (min-width: 992px)": {
			maxWidth: "840px"
		},
		"@media (min-width: 1200px)": {
			maxWidth: "840px"
		}
	},
	title:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
	},
	bgname:{
		height: "82px",
		fontSize: "61px",
		backgroundImage: `url(${bgname})`,
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		textAlign: 'center',
		color:'#ffffff',
		backgroundRepeat:'no-repeat',
		fontWeight:'bolder',
		fontFamily:"Pomeranian",
		marginTop: "-48px"
	},
	head: {
		width:"100px"
	},
	containerTitle:{
		border : "solid 3px #85cad1",
		marginTop: "32px"
	},
	text:{
		color: "#5cc2c7",
		textAlign:"center",
		fontFamily:"Pomeranian",
		fontSize: "32px",
	},
	productName:{
		color: "#5cc2c7",
		textAlign:"left",
		fontFamily:"Pomeranian",
		fontSize: "20px",
		margin: "6px",
	},
	productPrice:{
		color: "#5cc2c7",
		textAlign:"left",
		fontFamily:"Pomeranian",
		fontSize: "18px",
		margin: "16px 6px",
		fontWeight:"bold",
	},
	product:{
		borderBottom: "solid 1px #5cc2c7",
		paddingBottom: "12px",
	},
	btn:{
		color: "#ffffff",
		background: "#5cc2c7",
		fontFamily:"Pomeranian",
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	},
	qty:{
		color: "#5cc2c7",
		fontWeight:"bold",
		fontFamily:"Pomeranian",
	},
	cart:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	},
	content:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	},
	titleSection:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
		fontSize:"14px"
	},
	tableContent:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	},
	textSection: {
		color: "#5cc2c7",
		border: "1px solid #5cc2c7",
		padding: "20px",
		marginRight: "30px"
	}
}));

const PaymentMethodStripe = ({orderProcess, setOrderProcess, processing, setProcessing}) => {

	let stripePromise = null;

	// do not return anything if stripe is disabled
	if (!orderProcess.data.methods.Stripe) return null

	if ( !orderProcess.data.methods.Stripe.fields.cc_token.attributes.options.stripe_account ) {
		stripePromise = loadStripe(orderProcess.data.methods.Stripe.fields.cc_token.attributes.key);
		
	} else {
		stripePromise = loadStripe(orderProcess.data.methods.Stripe.fields.cc_token.attributes.key, { stripeAccount: orderProcess.data.methods.Stripe.fields.cc_token.attributes.options.stripe_account});
	}

	return (
		<Elements stripe={stripePromise}>
			<PaymentMethodStripeInternal orderProcess={orderProcess} setOrderProcess={setOrderProcess} processing={processing} setProcessing={setProcessing}/>
		</Elements>
	)
	
}

const PaymentMethodStripeInternal = ({orderProcess, setOrderProcess, processing, setProcessing}) => {
	const {t} = useTranslation();
	const stripe = useStripe();
	const elements = useElements();

	if (!stripe || !elements) {
		return <CircularProgress/>;
	}

	const stripeCallback = () => {
		setProcessing(true);

		// get ref to cardElement
		const cardElement = elements.getElement(CardElement);

		stripe.createToken(cardElement)
			.then((token) => {
				if (!token) throw new Error("Stripe token not initialized");
        
				return rest('Order/'+orderProcess.data.order.Order__+':process', 'POST', {method:orderProcess.data.methods.Stripe.method,session:orderProcess.data.methods.Stripe.session,cc_token:token.token.id});
			})
			.then(res => {
				setOrderProcess(res);
				setProcessing(false);
			})
			.catch((e) => {
				// TODO error handling
				setProcessing(false);
			});
	}

	const cardOptions = { 
		iconStyle: "solid",
		hidePostalCode: true,
		style: {
			base: {
				iconColor: '#737475',
				color: '#737475',
				fontWeight: 500,
				fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				':-webkit-autofill': {color: '#737475'},
				'::placeholder': {color: '#737475'},
			},
			invalid: {
				iconColor: '#ff0000',
				color: '#ff0000',
			},
		},
	};

	return <Grid item xs={12}>
		<div border={2} p={2} style={{"padding":"10px"}}>
			<Grid item xs={12} style={{"padding":"unset"}}>
				<p style={{fontSize:"11px",color:"#888888"}}>{t("lbl_card_payment_terms")}</p>

				<div style={{border:"solid 2px #5cc2c7", "padding":"24px 12px", marginBottom: "12px"}}>
					<CardElement options={{cardOptions}}/>
				</div>
				<Grid item xs={12}>
					<Grid container alignItems="center" direction='column' spacing={3}>
						<Grid item xs={12} style={{textAlign: "right", paddingRight:"0"}}>
							{!processing && <Button color='primary' variant='contained' onClick={stripeCallback}>
								{t("order_card_btn")}
							</Button>}
							{processing && <CircularProgress/>}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	</Grid>;
}

const PaymentMethodFurikomi = ({orderProcess, setOrderProcess, processing, setProcessing}) => {
	const {t} = useTranslation();

	if (!orderProcess.data.methods.AozoraNet) return null;

	const furikomiCallback = () => {
		setProcessing(true);

		// TODO check if AozoraNet method is available
		let current = orderProcess.data.methods.AozoraNet;

		rest('Order/'+orderProcess.data.order.Order__+':process', 'POST', {method: current.method, session: current.session})
			.then(res => {
				setOrderProcess(res);
				setProcessing(false);
			})
			.catch((e) => {
			// TODO error handling
				setProcessing(false);
			});
	}

	return  <Grid item xs={12}>
		<div border={2} p={2} style={{"padding":"10px"}}>
			<Grid item xs={12} style={{"padding":"unset"}}>
				<Grid item xs={12}>
					<Grid container alignItems="center" direction='column' spacing={3}>
						<p>{t("lbl_furikomi_info")}</p>
						<Grid item xs={12} style={{textAlign: "right", paddingRight:"0"}}>
							{!processing && <Button color='primary' variant='contained' onClick={furikomiCallback}>
								{t("order_furikomi_btn")}
							</Button>}
							{processing && <CircularProgress/>}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	</Grid>
}

const PaymentMethodsFurikomiInfo = ({orderProcess, setOrderProcess}) => {
	const classes = useStyles();
	const {t} = useTranslation();

	return <Grid container style={{margin:0}}>
		<Grid container justify="left">
			<Grid item xs={12}>
				<div className={classes.titleContainer}>
					<h2 className={classes.titleSection}> {t('order_select_payment')} </h2>
				</div>
			</Grid>
		</Grid>

		<Grid item xs={12}>
			<div className={classes.textSection} style={{ whiteSpace: 'pre-line' }}>{t('order_furikomi_aozoranet', {order: orderProcess.data.order})}</div>
		</Grid>
	</Grid>
};

const PaymentMethods = ({orderProcess, setOrderProcess}) => {
	const classes = useStyles();
	const {t} = useTranslation();
	const [processing, setProcessing] = useState(false);

	if (!orderProcess.data.order_payable) {
		// this means order is processing, or done.
		if (orderProcess.data.order.Status === "pending-initiated") {
			// may need to show more info
			switch(orderProcess.data.order.Payment_Class) {
			case "AozoraNet":
				return <PaymentMethodsFurikomiInfo orderProcess={orderProcess}/>
			default:
				break;
			}
		}
		return null;
	}

	return <Grid container style={{margin:0}}>
		<Grid container justify="left">
			<Grid item xs={12}>
				<div className={classes.titleContainer}>
					<h2 className={classes.titleSection}> {t('order_select_payment')} </h2>
				</div>
			</Grid>
		</Grid>

		<PaymentMethodFurikomi orderProcess={orderProcess} setOrderProcess={setOrderProcess} processing={processing} setProcessing={setProcessing}/>
		<PaymentMethodStripe orderProcess={orderProcess} setOrderProcess={setOrderProcess} processing={processing} setProcessing={setProcessing}/>
	</Grid>
}

const PaymentComplete = () => {
	return <img src={ImagePaymentComplete} style={{width: '100%', height: 'auto'}} alt="Thank you for your order"/>
}

const PaymentStatus = ({orderProcess, setOrderProcess}) => {
	// 'open','pending','pending-initiated','unpaid','pending-paid','paid','completed','cancelled','chargeback','chargeback-reversed','forcedcollection','settled','refunded','expired'
	switch (orderProcess.data.order.Status) {
	case 'pending-paid':
	case 'paid':
	case 'completed':
		return <PaymentComplete/>;
	default:
		return <PaymentMethods orderProcess={orderProcess} setOrderProcess={setOrderProcess} />;
	}
}


export default () => {
	//const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const {t} = useTranslation();
	const [orderProcess, setOrderProcess] = useState();
	const order = orderProcess ? orderProcess.data.order : null;
	const slug = useParams();

	useEffect(() => {
		rest('Order/'+slug.order+':process', 'POST')
			.then(res => {
				setOrderProcess(res);
			})
			.catch((e) => {
			// TODO error handling
			});
	}, [slug.order])

	//const [continueEnabled, setContinueEnabled] = useState(false);

	if (!orderProcess) {
		// loading in progress
		return <Grid container className={classes.root} justify="center" spacing={2}>
			<Backdrop className={classes.backdrop} open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Grid>
	}

	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			<div className={classes.container} >

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<p className={classes.title}>{t("order_title")}</p>
                            
						<Grid container
							className={classes.section}
							direction="row"
							justify="flex-start">

							<Grid item xs={12} sm={6}>
								<PaymentStatus orderProcess={orderProcess} setOrderProcess={setOrderProcess} />
							</Grid>

							<Grid item xs={12} sm={6}>
          
								<Grid container style={{margin:0}}>

									<Grid container justify="left">

										<Grid item xs={12}>
											<div className={classes.titleContainer}>
												<h2 className={classes.titleSection}> {t('order_summary')} </h2>
											</div>
										</Grid>

									</Grid>

									<Grid item xs={12}>
										<div border={2} borderColor="#5cc2c7" p={2} style={{background:"#FBFCFE"}}>
											<Grid container spacing={2}>
												<Grid item xs={12}>

													<Grid container spacing={2}>
														<Grid item xs={12}>
															<TableContainer style={{marginBottom:"24px"}}>
																<Table>
																	<TableHead>
																		<TableRow>
																			<TableCell className={classes.tableContent}>{t('order_product_name')}</TableCell>
																			<TableCell className={classes.tableContent}>{t('quantity_lbl')}</TableCell>
																			<TableCell className={classes.tableContent}>{t('order_price')}</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{order && order.Items.map((p) =>
																			<TableRow>
																				<TableCell className={classes.tableContent}>{p.Catalog_Product["Basic.Name"]}</TableCell>
																				<TableCell className={classes.tableContent}>{p.Quantity}</TableCell>
																				<TableCell className={classes.tableContent} align="right">{p.Price.display}</TableCell>
																			</TableRow>
																		)}
																		{order && 
																		<>
																			<TableRow>
                                                  
																				<TableCell className={classes.tableContent} colSpan={2}>
																					{t('cart_subtotal')}
																				</TableCell>
																				<TableCell className={classes.tableContent} align="right">
																					{order.Subtotals.regular.display}
																				</TableCell>
																			</TableRow>
																			<TableRow>
																				<TableCell className={classes.tableContent} colSpan={2}>
																					{t('cart_tax')}
																				</TableCell>
																				<TableCell className={classes.tableContent} align="right">
																					{order.Vat_Amount.display}
																				</TableCell>
																			</TableRow>
																			<TableRow>
																				<TableCell className={classes.tableContent} colSpan={2}>
																					{t('cart_total')}
																				</TableCell>
																				<TableCell className={classes.tableContent} align="right">
																					{order.Total_Vat.display}
																				</TableCell>
																			</TableRow>
																		</>
																		}
																	</TableBody>

																</Table>
															</TableContainer>
														</Grid>
													</Grid>
                            
												</Grid>
											</Grid>
										</div>
									</Grid>

								</Grid>

							</Grid>
          
						</Grid>

                            
                            
					</Grid>
				</Grid>

			</div>
		</Grid>
	);
};
