import React, {useState} from "react";
import AvatarEditor from 'react-avatar-editor';
import {upload} from '@karpeleslab/klbfw';


// style
import styles from "../../SCSS/account.module.scss";

// img

// image
import defaultAvatar from '../../assets/img/default-avatar.svg'

const Avator = (props) => {
	const {user, refresh} = props;
	const [imgPath, setImgPath] = useState(null);
	const [scale, setScale] = useState(1);
	let croppieFileInput = null;
	let editor = null;

	const updateAvatar = (files) => {

		upload.onprogress = (d) => {
			if (d.queue.length !== 0) return;
			let allComplete = true;

			d.running.forEach((value) => {
				if (value.status !== 'complete')
					allComplete = false;
				if (allComplete) {
					refresh()
				}
			});
		};

		for (const vf in files) {
			if (files[vf].file) {
				upload.append(files[vf].target, files[vf].file, files[vf].param);
				upload.run();
			}
		}
	};

	const avatorImage = () => {
		if (imgPath !== null) {
			return <div className={styles['avatar-editor-images']}>
				<div className={styles['avatar-editor-images-inner']}>
					<AvatarEditor
						ref={setEditorRef}
						image={imgPath}
						width={170}
						height={170}
						borderRadius={100}
						border={0}
						scale={scale}
						rotate={0}
						className={styles['avatar-editor']}
					/>
				</div>
				<input
					name="scale"
					type="range"
					onChange={handleScale}
					min="1"
					max="2"
					step="0.01"
					defaultValue="1"
					className={styles['avatar-editor-range']}
				/>
			</div>;
		}
		else {
			return <figure className={styles['avatar-images']}>
				<img src={user.data.Profile.Media_Image ? user.data.Profile.Media_Image.Url : defaultAvatar} alt="default avatar" className={styles['avatar']} />
			</figure>;
		}
	}

	const setEditorRef = e => {
		if (e) {
			editor = e;
		}
	}

	const handleNewImage = (e) => {
		setImgPath(e.target.files[0])
	}

	const handleScale = e => {
		setScale(parseFloat(e.target.value))
	}
	
	const handleRemove = () => {
		setImgPath(null)
		setScale(1)
		croppieFileInput.value = "";
	}

	const upLoadHandler = () => {
		editor.getImageScaledToCanvas().toBlob( (blob) => {
			blob['name'] = 'avatar.png';
			updateAvatar({
				profile_pic: {
					target: "User/@/Profile:addImage",
					param: { purpose: "main" },
					file: blob
				}
			});
		});
	}
	
	return (
		<>
			<div className={styles['profile-avatar']} >
				<div className={styles['avatar-wrapper']}>

					<div>
						<div>
							{avatorImage()}
						</div>
					</div>
					<div className={`${styles['avatar-btns']} ${styles['avatar-btns--profile']}`}>
						<div className={`${styles['avatar-btn-wrapper']} ${styles['avatar-btn-wrapper--profile']}`}>
							<label
								className={`${styles['file-input']} ${styles['avatar-btn']} ${styles['avatar-btn--upload']}`}
							>
							画像をアップロード
								<input 
									ref={ref => croppieFileInput = ref}
									type="file" 
									accept="image/png, image/jpeg, image/jpg, image/svg"
									onChange={handleNewImage}
								/>
							</label>
						</div>
						<div className={styles['avatar-btn-wrapper']}>
							<button
								type="button"
								onClick={upLoadHandler}
								className={`${styles['avatar-btn']} ${styles['avatar-btn--save']}`}
								disabled={imgPath=== null}
							>保存</button>
						</div>
						<div className={styles['avatar-btn-wrapper']}>
							<button
								type="button"
								className={styles['avatar-btn']}
								disabled={imgPath=== null}
								onClick={() => {handleRemove()}}
							>削除</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Avator;