import React, {useState}  from 'react';
import {useLocation, useHistory} from "react-router-dom"
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import classNames from 'classnames';
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import {rest} from "@karpeleslab/klbfw";

// icon
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

// css
import styles from "../SCSS/header.module.scss";

// img
import MainLogo from '../assets/img/mainlogo.png';
import Patra from '../assets/img/patra.png';
import Ria from '../assets/img/ria.png';
import TwitterBtn from '../assets/img/twitter_btn.png';
import YoutubeBtn from '../assets/img/youtube_btn.png';

import HeaderBg from '../assets/img/header_bg.png';
import TopLine from '../assets/img/topline.png';
import separatorBottom from '../assets/img/bg_header_bottom.png';

const bgStyle = {backgroundImage:`url(${HeaderBg})`};
const topBgStyle = {backgroundImage:`url(${TopLine})`};
const bottomBgStyle = {backgroundImage:`url(${separatorBottom})`};

export default () => {
	const {t} = useTranslation();
	const page = useLocation();
	const history = useHistory();
	const [user] = useRest("User:get");
	const userRefresh = useRestRefresh("User:get");
	const [menuActive, setMenuActive] = useState(false);


	const logout = () => {
		rest("User/@:logout", "POST")
			.then(
				(data) => {
					userRefresh();
					history.push('/');
				}
			);
	}


	const buildMenuIcon = () => {
		if (menuActive) {
			return <CloseIcon />
		}
		else {
			return <MenuIcon />
		}
	}

	const activeMenu = () => {
		if (menuActive) {
			setMenuActive(false)
		}
		else {
			setMenuActive(true)
		}
	}
  
	const clickLink = () => {
		setMenuActive(false)
	}

	const Login = () => {

		if (user === null) return false;

		if (user.data === null) {
			return (
				<li className={classNames(`${styles['menu-item']}`,
					{
						[styles['menu-item--active']]: page.pathname === "/login"
					}
				)}>
					<Link
						onClick={()=>{clickLink()}}
						to="/login">{t('menu_login_link')}</Link>
				</li>
			)
		}
		else {
			return (
				<>
					<li className={classNames(`${styles['menu-item']}`,
						{
							[styles['menu-item--active']]: page.pathname === "/account"
						}
					)}>
						<Link
							onClick={()=>{clickLink()}}
							to="/account">{t('menu_account_link')}</Link>
					</li>
					<li
						className={`${styles['menu-item']}`}
					>
						<span
							onClick={() => {logout()}}
						>
							{t('menu_logout_link')}
						</span>
					</li>
				</>
			)
		}
	}

	return (
		<header
			className={styles['header']}
			style={bgStyle}
		>
			<div
				className={styles['sp-btn']}
				onClick={() => {activeMenu()}}
			>
				{buildMenuIcon()}
			</div>
			<div
				className={styles['header-bg-top']}
				style={topBgStyle}
			></div>
			<div className={styles['header-logo']}>
				<h1 className={styles['header-logo-main']}>
					<Link to="/">
						<img src={MainLogo} alt="ぱとらりあチャンネル" />
					</Link>
				</h1>
				<span className={styles['header-logo-patra']}><img src={Patra} alt="ぱとら" /></span>
				<span className={styles['header-logo-ria']}><img src={Ria} alt="りあ" /></span>
			</div>
      
			<div
				className={classNames(`${styles['menu']}`,
					{
						[styles['menu-active']]: menuActive === true
					}
				)}>
				<ul>
					<li className={classNames(`${styles['menu-item']}`,
						{
							[styles['menu-item--active']]: page.pathname === "/profile"
						}
					)}>
						<Link
							onClick={()=>{clickLink()}}
							to="/profile">{t('menu_profile_link')}</Link>
					</li>
					<li className={classNames(`${styles['menu-item']}`,
						{
							[styles['menu-item--active']]: page.pathname === "/contact"
						}
					)}>
						<Link
							onClick={()=>{clickLink()}}
							to="/contact">{t('menu_contact_link')}</Link>
					</li>
					<li className={classNames(`${styles['menu-item']}`,
						{
							[styles['menu-item--active']]: page.pathname === "/fanclub"
						}
					)}>
						<Link
							onClick={()=>{clickLink()}}
							to="/fanclub">{t('menu_fanclub_link')}</Link>
					</li>
					{Login()}
					<li className={`${styles['menu-item']} ${styles['menu-item-sns']}`}>
						<a href="https://twitter.com/patraria_rt" target="_blank" rel="noopener noreferrer">
							<img src={TwitterBtn} alt="Twitter"/>
						</a>
						<a href="https://youtube.com/c/PatrariaChannel" target="_blank" rel="noopener noreferrer">
							<img src={YoutubeBtn} alt="Youtube"/>
						</a>
					</li>
				</ul>
				<div
					className={styles['menu-bg-bottom']}
					style={topBgStyle}
				></div>
			</div>
			<div
				className={styles['header-bg-bottom']}
				style={bottomBgStyle}
			></div>
		</header>
	);
};
