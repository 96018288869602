import React from 'react';
import {Get} from "@karpeleslab/klbfw";
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();
	return (
		<div id="mail-text">
			{t('mail_user_name', {name:`${Get('_order').Billing_User_Location.Last_Name} ${Get('_order').Billing_User_Location.First_Name}`})}

			{t('mail_order_thanks')}
			{t('mail_order_complete_text')}

			{t('mail_order_complete_text_02')}

			■{ t('mail_shipping_label') }
			{ t('mail_shipping_text') }

			■{t('mail_download_sub')}
			{t('mail_download_text_1')}
			{t('mail_download_text_2')}
			https://patraria.jp/order_history


■{t('mail_contact')}
https://patraria.jp/contact

https://patraria.jp/
		</div>
	);
};

export default Text;