import React, {useState} from "react";
import {rest} from '@karpeleslab/klbfw';
import Datetime from "react-datetime";
import moment from "moment";
import 'moment/locale/ja';



// style
import styles from "../../SCSS/account.module.scss";
import formStyles from '../../SCSS/login.module.scss';
import "../../SCSS/react-datetime.css";


const Info = (props) => {

	const {user, refresh} = props;
	const [profile, setProfile] = useState({
		Birthdate: user.data.Profile.Birthdate,
		Gender: user.data.Profile.Gender,
	})



	const onInputChangeHandler = (e) => {
		setProfile({
			...profile,
			[e.target.getAttribute('name')]: e.target.value
		})
	};


	const btnDisable = () => {
		let flg = true;
		if (profile.Birthdate !== null) {
			flg = !profile.Birthdate.match(/\d{4}-\d{2}-\d{2}/);
		}

		return flg ? flg : user.data.Profile.Gender === profile.Gender && user.data.Profile.Birthdate === profile.Birthdate;
	};


	const dateHandler = (e) => {
		if (!e._isValid) {
			setProfile({
				...profile,
				Birthdate: e
			})
		} else {
			setProfile({
				...profile,
				Birthdate: e.format('YYYY-MM-DD')
			})
		}
	};

	const onSubmitHandler = (e) => {
		e.preventDefault();
		rest('User/@/Profile', 'PATCH', profile)
			.then(
				() => {
					refresh()
				}
			)
			.catch((err) => {
				refresh()
				throw err
			});
	}

	const isValidBirthDate = (bd) => {
		return bd && bd !== '0000-00-00' // 0000-00-00 is the default value in the db
	};

	const formatDate = (date) => {
		if (date.match(/\d{4}-\d{2}-\d{2}/)) {
			return moment(date).format('YYYY年MM月DD日')
		} else {
			return date;
		}
	};

	const birthDateDefaultValue = () => {
		if (profile.Birthdate) return isValidBirthDate(profile.Birthdate) ? formatDate(profile.Birthdate) : '';
		return isValidBirthDate(user.data.Profile.Birthdate) ? formatDate(user.data.Profile.Birthdate) : ''
	};

	return (
		<>
			<div className={styles.profile}>
				<h3>プロフィール情報</h3>
				<form onSubmit={onSubmitHandler}>

					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`}>
						<label htmlFor='birthday'>誕生日</label>
						<Datetime value={birthDateDefaultValue()} dateFormat="YYYY年MM月DD日" inputProps={{
							autoComplete: "off",
							name: 'Birthdate',
							id: 'birthday',
							required: "required"
						}} onChange={moment => dateHandler(moment)} timeFormat={false} locale="ja-JP"/>
					</div>

					<div className={`${formStyles['form-field']} ${formStyles['form-field--select']}`}>
						<label htmlFor='gender'>性別</label>
						<select id='gender' name='Gender' value={profile.Gender ?? ''}
							onChange={onInputChangeHandler}>
							<option value=''>選択してください</option>
							<option value='M'>男性</option>
							<option value='F'>女性</option>
						</select>
					</div>

					<div className={formStyles['form-btn-wrapper']}>
						<button
							disabled={btnDisable()}
							className={`${formStyles['form-btn']} ${formStyles['form-btn--small']}`}
							type='submit'>変更
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default Info;