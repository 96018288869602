import React from 'react';
import {Get} from "@karpeleslab/klbfw";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const Html = () => {
	const { t } = useTranslation();

	const Payment_Class = () => {
		if (Get('_order').Payment_Class !== "") {
			return (
				<tr>
					<td width="20%" nowrap>
						{t('pdf_payment')}
					</td>
					<td>
                        : {Get('_order').Payment_Method}
					</td>
				</tr>
			)
		}
	};

	const Shipping_User_Location = () => {
		if (Get('_order').Shipping_User_Location) {
			return (
				<td valign="top">
					<h3><font color="#33212f">{t('mail_shipping')}</font></h3>
					<p>{Get('_order').Shipping_User_Location.Display[0]} {Get('_order').Shipping_User_Location.Display[1]}</p>
				</td>
			)
		} else {
			return (
				<td valign="top">
					<h3><font color="#33212f">{t('mail_shipping')}</font></h3>
					<p>{Get('_order').Billing_User_Location.Display[0]} {Get('_order').Billing_User_Location.Display[1]}</p>
				</td>
			)
		}
	};


	const quantity = (line) => {
		if (line.Quantity) {
			return line.Quantity
		} else {
			return '1'
		}
	}

	const items = () => {
		return Get('_order').Items.map((line) => {
			return (
				<tr>
					<td className="prod_name">
						{line.Catalog_Product['Basic.Name']}
					</td>
					<td className="unit_price" align="right">
						{line.Price.display}
					</td>
					<td className="quantity" align="center">
						{quantity(line)}
					</td>
					<td className="prod_total" align="right">
						{line.Full_Price.display}
					</td>
				</tr>

			)
		})
	};

	return (
		<div id="mail-html">
			<div style={{
				color: "#666",
				marginTop: "-6px"
			}}>
				<table cellSpacing="0" cellPadding="5" border="0" align="center" width="600" style={{
					lineHeight: "1.4rem",
					color:"#989d9f"
				}}>
					<tr>
						<td style={{
							textAlign: "center",
							background: "#707070",
							padding: "20px"
						}}>
							<img src="https://patraria.jp/static/media/mainlogo.1f6a6afa.png" width="200px" alt="Pateraria Channel"/>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							{t('mail_user_name', {name:`${Get('_order').Billing_User_Location.Last_Name} ${Get('_order').Billing_User_Location.First_Name}`})}<br />
							<br />
							{t('mail_order_thanks')}
						</td>
					</tr>
					<tr>
						<td>
							<table cellSpacing="0" cellPadding="10" border="1" bordercolor="#ccc" align="center" width="100%"
								style={{
									borderCollapse: "collapse",
									lineHeight: "1.4rem",
									border: "1px solid #ccc"
								}}>
								<tr>
									<td bgcolor="#e8f6fc">
										<b>{t('mail_order_no')}</b> #{Get('_order').Invoice_Number}<br />
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<table cellSpacing="0" cellPadding="0" border="0" align="center" width="100%" style={{lineHeight:"1.4rem"}}>
								<tr>
									<td width="20%" nowrap>
										{t('mail_order_date')}	
									</td>
									<td>
                                        : {moment(Get('_order').Created?.unix * 1000).format('YYYY/MM/DD HH:mm:ss')}
									</td>
								</tr>
								{Get('_order').Invoice_Date &&
								<tr>
									<td width="20%" nowrap>
										{t('mail_payment_date')}
									</td>
									<td>
                                        : {moment(Get('_order').Invoice_Date?.unix * 1000).format('YYYY/MM/DD HH:mm:ss')}
									</td>
								</tr>
								}
								{Payment_Class()}
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							<table cellSpacing="0" cellPadding="5" border="0" align="center" width="100%" style={{
								lineHeight: "1.4rem"
							}}>
								<tr>
									<td valign="top">
										<h3><font color="#33212f">{t('mail_billing_address')}</font></h3>
										<p>{Get('_order').Billing_User_Location.Display[0]} {Get('_order').Billing_User_Location.Display[1]}</p>
									</td>
									{Shipping_User_Location()}
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							<h3><font color="#33212f">{t('mail_order_details')}</font></h3>
							<table cellSpacing="0" cellPadding="5" border="1" bordercolor="#cccccc" align="center" width="100%" style={{
								borderCollapse: "collapse",
								lineHeight: "1.4rem",
								border: "1px solid #ccc"
							}}>
								<tr>
									<th bgcolor="#e8f6fc">{t('mail_product_name')}</th>
									<th bgcolor="#e8f6fc">{t('mail_price')}</th>
									<th bgcolor="#e8f6fc">{t('mail_quantity')}</th>
									<th bgcolor="#e8f6fc">{t('mail_subtotal')}</th>
								</tr>
								{items()}
								<tr>
									<td className="subtotal_h" colSpan="3" align="right" bgcolor="#e8f6fc">{t('mail_subtotal')}</td>
									<td className="subtotal" align="right">
										{Get('_order').Total.display}
									</td>
								</tr>
								<tr>
									<td className="shippingfee_h" colSpan="3" align="right" bgcolor="#e8f6fc">{t('mail_discount')}</td>
									<td className="shippingfee" align="right">
										{Get('_order').Subtotals.discount.display}
									</td>
								</tr>
								<tr>
									<td className="shippingfee_h" colSpan="3" align="right" bgcolor="#e8f6fc">{t('mail_shipping_fee')}</td>
									<td className="shippingfee" align="right">
										{Get('_order').Subtotals.shipping.display}
									</td>
								</tr>
								<tr>
									<td className="tax_h" colSpan="3" align="right" bgcolor="#e8f6fc">{t('mail_tax')}</td>
									<td className="total" align="right">
										{Get('_order').Vat_Amount.display}
									</td>
								</tr>
								<tr className="noborder">
									<td className="total_h" colSpan="3" align="right" bgcolor="#e8f6fc"><b>{t('mail_total')}</b></td>
									<td className="total" align="right">
										<b>{Get('_order').Total_Vat.display}</b>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							<h3><font color="#33212f">{ t('mail_shipping_label') }</font></h3>
							<p style={{ whiteSpace: "pre-line" }}>{ t('mail_shipping_text') }</p>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							<h3><font color="#33212f">{t('mail_download_sub')}</font></h3>
							<p>{t('mail_download_text_1')}</p>
							<p>{t('mail_download_text_2')}</p>
							<p><a href="https://patraria.jp/order_history">https://patraria.jp/order_history</a></p>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							<h3><font color="#33212f">{t('mail_contact')}</font></h3>
							<p><a href="https://patraria.jp/contact">https://patraria.jp/contact</a></p>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b"/>
						</td>
					</tr>
					<tr>
						<td>
							<p>
								<a href="https://patraria.jp/">https://patraria.jp/</a>
							</p>
						</td>
					</tr>
				</table>
			</div>
		</div>
	);
};

export default Html;