import React from "react";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import isLoggedIn from '../../helpers/is_logged_in.js';
import moment from "moment";
import _ from 'lodash';

// style
import styles from "../../SCSS/history.module.scss";


export default () => {

	const { order } = useParams();
	const { t } = useTranslation();
	const [ orderDetail ] = useRest(`Order/${order}`, "GET");

		
	isLoggedIn();

	const buildInvoiceNum = () => {

		if (orderDetail.data.Invoice_Number === null) {
			return "-";
		}
		else {
			return orderDetail.data.Invoice_Number;
		}

	}

	const buildHead = () => {
		return (
			<div className={styles['detail-head']}>
				<p className={styles['detail-head-num']}>{buildInvoiceNum()}</p>
				<div className={`${styles['detail-head-item']}`}>
					<div className={classNames(`${styles['detail-head-status']}`,
						{
							[styles['detail-head-status-disabled']]: orderDetail.data.Status !== "completed"
						}
					)}>
						<p>状態：{t(`status_${orderDetail.data.Status}`)}</p>
					</div>
					<div className={classNames(`${styles['detail-head-pay']}`,
						{
							[styles['detail-head-status-disabled']]: orderDetail.data.Invoice_Status !== "done"
						}
					)}>
						<p>支払い状態；{t(`status_${orderDetail.data.Invoice_Status}`)}</p>
					</div>
				</div>
			</div>
		)
	}

	const buildItem = () => {
		return orderDetail.data.Items.map((item, idx) => {
			return (
				<dl key={idx}>
					<div>
						<dt>商品名</dt>
						<dd>{item.Catalog_Product['Basic.Name']}</dd>
					</div>
					<div>
						<dt>個数</dt>
						<dd>{item.Quantity}</dd>
					</div>
					<div>
						<dt>価格</dt>
						<dd>{item.Price.display}</dd>
					</div>
				</dl>
			)
		})
	}

	const buildAllPrice = () => {
		return (
			<dl className={styles['detail-total']}>
				<div>
					<dt>小計</dt>
					<dd>{orderDetail.data.Total.display}</dd>
				</div>
				<div>
					<dt>消費税</dt>
					<dd>{orderDetail.data.Vat_Amount.display}</dd>
				</div>
				<div>
					<dt>合計</dt>
					<dd>{orderDetail.data.Total_Vat.display}</dd>
				</div>
			</dl>
		)
	}


	const buildInvoicePdf = () => {
		if ( orderDetail.data.Invoice_Url === null || orderDetail.data.Invoice_Url === void 0 ) return false;
		return (
			<a href={orderDetail.data.Invoice_Url}>請求書PDF</a>
		)
	}


	const buildDownload = () => {

		if (orderDetail.data.Invoice_Url === void 0) return null;

		return (
			<div className={styles['detail-download']}>
				<h3>請求書</h3>
				<p>下記より請求書PDFのダウンロードが行えます。</p>
				<div className={styles['detail-download-inner']}>
					{buildInvoicePdf()}
				</div>
			</div>
		)
		
	}


	const buildItems = () => {
		return (
			<div className={styles['detail-wrapper']}>
				<p className={styles['detail-date']}>注文日時：{moment(orderDetail.data.Created.iso).format('YYYY/MM/DD h:mm:ss')}</p>
				{ buildHead() }
				<div className={styles['detail-item']}>
					{buildItem()}
				</div>
				{buildAllPrice()}
			</div>
		)
	}

	const buildAudio = (Files) => {
		return Files.map((file, idx) => {
			if (file.Drive_Item.Media_Audio) {
				return (
					<div key={idx} className={styles['detail-preview-item']}>
						<p>{file.Name}</p>
						<audio controls>
							{
								_.map(file.Drive_Item.Media_Audio.Variation, (item, key) => {
									return <source key={key} src={item} type={`audio/${key.split("=")[1]}`} />
								})
							}
						</audio>
					</div>
				)
			}
			else {
				return (
					<div key={idx} className={styles['detail-preview-item']}>
						<p>{file.Name}</p>
						<a href={file.Drive_Item.Download_Url}>ダウンロード</a>
					</div>
				)
			}
		});
	}

	const buildPreviewList = () => {
		let list = [];

		for(let idx in orderDetail.data.Items) {
			let item = orderDetail.data.Items[idx];
			if ((item.Deliverable === null) || (!item.Deliverable.Files)) continue;

			list.push(
				<div className={styles['detail-preview-list-wrapper']} key={idx}>
					<h4>{item.Catalog_Product['Basic.Name']}</h4>
					<div className={styles['detail-preview-list']}>
						{buildAudio(item.Deliverable.Files)}
					</div>
				</div>
			);
		}

		if (list.length === 0) {
			return <p>プレビュー、ダウンロードが可能な商品はございません。</p>
		}

		return (
			<div className={styles['detail-preview']}>
				<h3>プレビュー、ダウンロード</h3>
				{list}
			</div>
		)
	}


	if (orderDetail === null) return false

	return (
		<div>
			<h2 className={styles['title']}>オーダー詳細</h2>
			<div className={styles['history-wrapper']}>
				<div className={styles['history']}>
					{ buildItems() }
					{ buildPreviewList() }
					{ buildDownload() }
				</div>
			</div>
		</div>
	);
};
