import React from "react";
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import {Link} from "react-router-dom";
import isLoggedIn from '../../helpers/is_logged_in.js';

// component
import Avator from "./avator"
import AccountInfo from "./info";
import Profile from "./profile";
import Address from "./address";

// style
import styles from "../../SCSS/account.module.scss";


const Account = () => {
	const [user] = useRest("User:get");
	const [location] = useRest("User/@/Location");
	const [country] = useRest("Country/JP:getProvinces");
	const userRefresh = useRestRefresh("User:get");
	const locationRefresh = useRestRefresh("User/@/Location");

	const buildUserInfo = () => {
		
		isLoggedIn();

		if (user === null || user.data === null || location === null || country === null ) return false;

		return (
			<>
				<div className={`${styles.profile}`}>
					<h3>ユーザー情報</h3>
					<div className={`${styles['profile-wrapper']}`}>
						<Avator
							user={user}
							refresh={userRefresh}
						/>
						<AccountInfo
							user={user}
							refresh={userRefresh}
						/>
					</div>
				</div>
				<div className={`${styles.profile}`}>
					<Profile
						user={user}
						refresh={userRefresh}
					/>
				</div>
				<div className={`${styles.profile}`}>
					<Address
						location={location}
						country={country}
						refresh={locationRefresh}
					/>
				</div>
				<div className={`${styles.profile}`}>
					<div className={`${styles.profile} ${styles['user-profile']}`}>
						<h3>購入情報</h3>
						<p>購入履歴はこちらのページから確認できます。ダウンロードが可能な商品のダウンロードが可能です。</p>
						<p className={styles['btn-wrapper']}><Link
							className={styles.btn}
							to="/order_history"
						>オーダー履歴はこちら</Link></p>
					</div>
				</div>
			</>
		)
	}


	return (
		<div>
			<h2 className={`${styles.title}`}>Account</h2>
			{buildUserInfo()}
		</div>
	)
}

export default Account;