import React from "react";
import {useLocation} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Grid, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import Helmet from "react-helmet";
import Status from '../../components/status';

// css
import '../../SCSS/cms.scss'

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	paper: {
		maxWidth: 450,
		padding: theme.spacing(2),
	},
	title:{
		textAlign:"center",
		color: "#5cc2c7",
		paddingBottom: '15px',
		"@media (max-width: 825px)": {
			paddingTop: "20px",
			fontSize: "24px"
		},
	},
	text:{
		color: "#5cc2c7",
		textAlign:"center",
	},
}));



export default ({base, cms}) => {
	const classes = useStyles();
	const {t} = useTranslation();
	const loc = useLocation();

	// not a big fan of using this but...
	// take pathname, trim base from it
	let pathname = loc.pathname;
	while(pathname.charAt(0)==='/') pathname = pathname.substring(1);
	// pathname should start with "base"
	if (pathname.indexOf(base) === 0)
		pathname = pathname.substring(base.length);
	while(pathname.charAt(0)==='/') pathname = pathname.substring(1);

	const [data] = useRest("Misc/HandleQuery:process", {base: base, path: pathname, "class": "Content/Cms", "id": cms});

	if (!data) {
		// loading
		return <Backdrop className={classes.backdrop} open={true}>
			<CircularProgress color="inherit" />
		</Backdrop>;
	}
	if ((data.error) || (!data.data.Vars._cms_entry_data)) {
		// not found, typically
		return <Status code={404}>
			<h1>Not found</h1>
		</Status>
	}

	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			<div className={classes.container} >
				<Helmet>
					<title>{t('title_page_cms', {title: data.data.Vars._cms_entry_data.Title})}</title>
				</Helmet>
			
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h2" className={classes.title}>{data.data.Vars._cms_entry_data.Title}</Typography>
					</Grid>
				</Grid>

				<Grid item container spacing={2}>
					<div className={`${'cms-content'}`} dangerouslySetInnerHTML={{__html : data.data.Vars._cms_entry_data.Contents}} />
				</Grid>
				
			</div>
		</Grid>
	);
};
