import React from 'react';
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import qs from 'qs';

const RedirectPage = () => {
	
	const location = useLocation();
	let hash = qs.parse(location.search.split('?')[1]);

	if (hash.return_to === void 0) {
		hash.return_to = "/cart"
	}

	return <Redirect to={hash.return_to} />
}

export default RedirectPage;
