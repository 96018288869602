import React, {useState} from "react";
import {rest} from '@karpeleslab/klbfw';



// style
import styles from "../../SCSS/account.module.scss";
import formStyles from '../../SCSS/login.module.scss';


const Address = (props) => {

	const {location, refresh, country} = props;
	const [locations, setLocations] = useState({
		First_Name: location.data[0].First_Name,
		Last_Name: location.data[0].Last_Name,
		Zip: location.data[0].Zip,
		Province: location.data[0].Province,
		Address: location.data[0].Address,
		City: location.data[0].City,
	})
	const [location_id] = useState(location.data[0].User_Location__)

	const	formSubmitHandler = (e) => {
		e.preventDefault();
		rest(`User/Location/${location_id}`, 'PATCH', {
			First_Name: locations.First_Name,
			Last_Name: locations.Last_Name,
			Zip: locations.Zip,
			Country__: 'JP',
			Address: locations.Address,
			City: locations.City,
			Province: locations.Province,
		})
			.then(
				() => {
					refresh()
				}
			)


	};

	const	onInputChangeHandler = (e) => {
		setLocations({
			...locations,
			[e.target.getAttribute('name')]: e.target.value
		})
	};

	const buildProvinces = () => {
		return (
			<div className={`${formStyles['form-field']} ${formStyles['form-field--select']}`}>
				<label htmlFor='province'>都道府県</label>
				<select name='Province' id='province' required={true}
					defaultValue={location.data[0].Province}
					onChange={onInputChangeHandler}>
					{
						country.data.map((province) =>
							<option value={province.key} key={province.key}>{province.name}</option>
						)
					}
				</select>
			</div>
		)
	};

	const hasChanges = () => {
		const keys = Object.keys(locations);
		for (let i = 0; i < keys.length; i++) {
			if (locations[keys[i]] !== location.data[0][keys[i]])
				return true;
		}
		return false;
	};


	return (
		<>
			<div className={`${styles.profile} ${styles['user-profile']}`}>
				<h3>住所変更</h3>
				<form onSubmit={formSubmitHandler}>
					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`}>
						<label htmlFor='lastname'>姓</label>
						<input name='Last_Name' id='lastname'
							defaultValue={locations.Last_Name} required={true}
							onChange={onInputChangeHandler}/>
					</div>
					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`}>
						<label htmlFor='firstname'>名</label>
						<input name='First_Name' id='firstname'
							defaultValue={locations.First_Name} required={true}
							onChange={onInputChangeHandler}/>
					</div>
					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`}>
						<label htmlFor='zip'>郵便番号</label>
						<input name='Zip' id='zip' required={true}
							defaultValue={locations.Zip}
							onChange={onInputChangeHandler}/>
					</div>
					{buildProvinces()}
					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`}>
						<label htmlFor='city'>市区町村</label>
						<input name='City' id='city' required={true}
							defaultValue={locations.City}
							onChange={onInputChangeHandler}/>
					</div>
					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`}>
						<label htmlFor='address'>住所</label>
						<input name='Address' id='address' required={true}
							defaultValue={locations.Address}
							onChange={onInputChangeHandler}/>
					</div>
					<div className={formStyles['form-btn-wrapper']}>
						<button
							disabled={!hasChanges()}
							className={`${formStyles['form-btn']} ${formStyles['form-btn--small']}`}
							type='submit'>変更
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default Address;