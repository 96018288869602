import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop,　Button, CircularProgress,　Grid, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from "@material-ui/core/TextField";
import { rest } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	title:{
		textAlign:"center",
		color: "#5cc2c7",
		paddingTop: '35px',
		paddingBottom: '15px',
		"@media (max-width: 825px)": {
			paddingTop: "20px",
			fontSize: "24px"
		},
	},
	btn:{
		color: "#ffffff",
		background: "#5cc2c7",
		'&:disabled': {
			color: "#ffffff",
			opacity:"0.4"
		},
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	},
	text:{
		textAlign:"center",
		margin: "0 0 15px"
	},
	container: {
		maxWidth: "825px",
		"@media (max-width: 825px)": {
			width: "100%"
		},
	},
	formContainer: {
		width: "100%",
		background: "#fff",
		padding: "20px",
		marginBottom: "20px"
	},
	linkBtn: {
		display: "inline-block",
		color: "#ffffff",
		background: "#5cc2c7",
		padding: "6px 8px",
		borderRadius: "4px",
		textDecoration: "none",
		'&:disabled': {
			color: "#ffffff",
			opacity:"0.4"
		},
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	}
}));


export default () => {
	const classes = useStyles();
	const {t} = useTranslation();

	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		email: ''
	});
	const [sent, setSent] = useState(false);

	const formChange = (event) => {
		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;

		setForm({
			...form,
			...array
		});

	};

	const emailSubmit = (event) => {
		event.preventDefault();

		setLoading(true);

		// // local check
		// setTimeout(() => {
		// 	setLoading(false);
		// 	setSent(true);
		// }, 3000)		

		rest("User:forgot_password", "POST", {login: form.email})
			.then(
				(data) => {
					if (data.result === "success") {
						setLoading(false);
						setSent(true);
					}
				}
			)
	}

	const buildProgress = () => {
		if (loading) {
			return (
				<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)
		} else {
			return null
		}
	}

	const buildContent = () => {
		if (sent) {
			return (
				<Grid item container spacing={2}>
					<div className={classes.formContainer}>
						<p className={classes.text}>{t('forget-password-comp')}</p>
						
						<Grid item xs={12} style={{textAlign: 'center'}}>
							<Link
								to="/"
								className={classes.linkBtn}
							>{t('forget-password-back')}</Link>
						</Grid>
					</div>
				</Grid>
			)
		} else {
			return (
				<Grid item container spacing={2}>
					<div className={classes.formContainer}>
						<p className={classes.text} dangerouslySetInnerHTML={{__html : t('forget-password-text')}} />
						<form onSubmit={(e) => {emailSubmit(e)}}>
							
							<Grid item xs={12}>
								<TextField
									label={t("forget-password-email")}
									placeholder={t("forget-password-email")}
									name='email'
									type="email"
									required
									fullWidth
									value={form.email}
									onChange={e => formChange(e)}
									variant="outlined"
									disabled={loading}
									className={classes.text}
								/>
							</Grid>

							<Grid item xs={12} style={{textAlign: 'center'}}>
								<Button className={classes.btn} disabled={loading || form.email.length === 0} type='submit'>
									{t("forget-password-submit")}
								</Button>
							</Grid>
						</form>
					</div>
				</Grid>
			)
		}
	}

	return (
		<Grid container className={classes.root} justify="center" spacing={2}>

			{buildProgress()}

			<div className={classes.container}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h2" className={classes.title}>{t('forget-password-title')}</Typography>
					</Grid>
				</Grid>

				{buildContent()}
			</div>
		</Grid>
	);
};
