import React from 'react';
import {Get} from '@karpeleslab/klbfw'
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();
	const recoverUrl =
        'https://patraria.jp/recover-password/' +
        Get('_recover_user').User_Id + '?k=' +
        encodeURIComponent(Get('_user_key'));

	return (
		<div id="mail-text">
			{ t('mail_forgotten_text_01') }

			{recoverUrl}
            
			{ t('mail_forgotten_text_02') }

			{ t('mail_name') }
		</div>
	);
};

export default Text;