import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Grid, Button} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import bgname from '../../assets/img/bgName.png'

import {rest, flushGet} from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import qs from 'qs';

//import NumericInput from 'react-numeric-input';
import { useSnackbar } from 'notistack';

import TextField from "@material-ui/core/TextField";
//import Countries from "../../components/countries/countries";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


// component
import Coupon from "../../components/coupon/coupon";

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
		"@media (max-width: 825px)": {
			marginTop: "10px"
		},
	},
	container:{
		width:"840px",
		padding:"16px",
		background:'#ffffff',
		"@media (min-width: 576px)": {
			maxWidth: "540px"
		},
		"@media (min-width: 768px)": {
			maxWidth: "720px"
		},
		"@media (min-width: 992px)": {
			maxWidth: "840px"
		},
		"@media (min-width: 1200px)": {
			maxWidth: "840px"
		}
	},
	title:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
	},
	bgname:{
		height: "82px",
		fontSize: "61px",
		backgroundImage: `url(${bgname})`,
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		textAlign: 'center',
		color:'#ffffff',
		backgroundRepeat:'no-repeat',
		fontWeight:'bolder',
		fontFamily:"Pomeranian",
		marginTop: "-48px"
	},
	head: {
		width:"100px"
	},
	containerTitle:{
		border : "solid 3px #85cad1",
		marginTop: "32px"
	},
	text:{
		color: "#5cc2c7",
		textAlign:"center",
		fontFamily:"Pomeranian",
		fontSize: "32px",
	},
	productName:{
		color: "#5cc2c7",
		textAlign:"left",
		fontFamily:"Pomeranian",
		fontSize: "20px",
		margin: "6px",
	},
	productPrice:{
		color: "#5cc2c7",
		textAlign:"left",
		fontFamily:"Pomeranian",
		fontSize: "18px",
		margin: "16px 6px",
		fontWeight:"bold",
	},
	product:{
		borderBottom: "solid 1px #5cc2c7",
		paddingBottom: "12px",
	},
	btn:{
		color: "#ffffff",
		background: "#5cc2c7",
		fontFamily:"Pomeranian",
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	},
	qty:{
		color: "#5cc2c7",
		fontWeight:"bold",
		fontFamily:"Pomeranian",
	},
	cart:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	},
	content:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	},
	titleSection:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
		fontSize:"14px"
	},
	tableContent:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	}
}));



function useGetCart(){
	return useRest("Catalog/Cart/@");
}


export default (props) => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const {t} = useTranslation();
	const [cart] = useGetCart();
	const [user, setUser] = useRest("User:get");
	//const [billing] = useGetBilling();
	const cartRefresh = useRestRefresh('Catalog/Cart/@')
	//const billingRefresh = useRestRefresh('User/@/Billing')
	//const { stripeStatus, processing,selectedBilling,setProcessing } = props;


	const [firstname,setFirstname] = useState("");
	const [lastname,setLastname] = useState("");
	const [nickname, setNickname] = useState('');
	const [zip,setZip] = useState("");
	const [city,setCity] = useState("");
	const [province,setProvince] = useState("");
	//const [country,setCountry] = useState("JP");
	const [address,setAddress] = useState("");
	const [address2,setAddress2] = useState("");
	const [contactPhone,setContactPhone] = useState("");

	//const [continueEnabled, setContinueEnabled] = useState(false);
	//const [complete, setComplete] = useState(false);
	const processing = false;

	const location = useLocation();
	const hash = qs.parse(location.search.split('?')[1]);

	const addToCart = (hash) => {

		if ( hash === void 0 ) {
			return false;
		}

		const query = {
			request: hash,
		};

		rest('Catalog/Cart/@:process', 'POST', query)
			.then(
				() => {
					cartRefresh();
					history.push(`/cart-redirect?return_to=/checkout`);
				}
			)
			.catch(
				(data) => {
					enqueueSnackbar(data.error);
				}
			)
	};

	useEffect(() => {
		addToCart(hash.product);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const createLocation = (e) => {
		if (e) e.preventDefault();

		rest('User/@/Location', 'POST', {
			First_Name: firstname,
			Last_Name: lastname,
			Nickname: nickname,
			Zip: zip,
			Country__: 'JP', //country,
			Address: address,
			Address2: address2,
			City: city,
			Province: province,
			Contact_Phone:contactPhone
		})
			.then((data) => {
				rest('User/@:setDefaultLocation', 'POST',{"User_Location__":data.data.User_Location__, "type": "Billing"})
					.then(
						(data) => {

							rest('Catalog/Cart/@:createOrder', 'POST')
								.then(
									(data) => {
										history.push("/order/"+data.data.Order__)
									}
								)
								.catch(
									(data) => {
										enqueueSnackbar(data);
									}
								)
						}
					)
					.catch(
						(data) => {
							enqueueSnackbar(data);
						}
					)

            
                
			})
			.catch((err) => {

			})
	}


	if (!user) {
		// loading
		return <Backdrop className={classes.backdrop} open={true}>
			<CircularProgress color="inherit" />
		</Backdrop>;
	}

	if (!user.data) {
		// user is not logged in
		return <Redirect to="/login?return_to=/checkout"/>
	}

	const logoutUser = () => {
		rest('User:logout', 'POST').then(() => {
			flushGet();
			setUser({data:null});
		});
	}

	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			<div className={classes.container} >

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<p className={classes.title}>{t("checkout_title")}</p>
                
						<Grid container
							className={classes.section}
							direction="row"
							justify="flex-start">

							<Grid item xs={12} sm={6}>

								<Grid container style={{margin:0}}>
									<Grid item xs={12}>
										{t('lbl_currently_logged_in_confirm', {user: user.data})}<br/>
										<span onClick={logoutUser}>{t('btn_wrong_user_logout')}</span>
									</Grid>

									<Grid container justify="flex-start">

										<Grid item xs={12}>
											<div className={classes.titleContainer}>
												<h2 className={classes.titleSection}> {t('enter_your_location')} </h2>
											</div>
										</Grid>

									</Grid>

									<Grid item xs={12}>
										<div border={2} p={2} style={{"padding":"10px"}}>
											<Grid item xs={12} style={{"padding":"unset"}}>

                          
												<Grid container spacing={1}>
													<Grid item xs={12}>
														<TextField id="outlined-basic" required label={t("nickname_lbl")} variant="outlined" fullWidth
															value={nickname} onChange={e => setNickname(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12} md={6}>
														<TextField id="outlined-basic" required label={t("lastname_lbl")} variant="outlined" fullWidth
															value={lastname} onChange={e => setLastname(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12} md={6}>
														<TextField id="outlined-basic" required label={t("firstname_lbl")} variant="outlined" fullWidth
															value={firstname} onChange={e => setFirstname(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12} md={6}>
														<TextField id="outlined-basic" required label={t("zip_lbl")} variant="outlined" fullWidth
															value={zip} onChange={e => setZip(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12} md={6}>
														<TextField id="outlined-basic" required label={t("province_lbl")} variant="outlined" fullWidth
															value={province} onChange={e => setProvince(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12} md={6}>
														<TextField id="outlined-basic" required label={t("city_lbl")} variant="outlined" fullWidth
															value={city} onChange={e => setCity(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12} md={6}>
														<TextField id="outlined-basic" required label={t("address_lbl")} variant="outlined" fullWidth
															value={address} onChange={e => setAddress(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12}>
														<TextField id="outlined-basic" label={t("address2_lbl")} variant="outlined" fullWidth
															value={address2} onChange={e => setAddress2(e.target.value)}
															disabled={processing}/>
													</Grid>
													<Grid item xs={12} md={6}>
														<TextField id="outlined-basic" required label={t("phone_lbl")} variant="outlined" fullWidth
															value={contactPhone} onChange={e => setContactPhone(e.target.value)}
															disabled={processing}/>
													</Grid>
													{/* <Grid item xs={12} md={6}>
														<Countries inputProps={{ fullWidth: true, required: true }} value={country}
															onChange={e => setCountry(e.target.value)}
															disabled={processing}/>
													</Grid> */}
												</Grid>
                                  
                                  

											</Grid>
										</div>
									</Grid>

								</Grid>
                            
							</Grid>

							<Grid item xs={12} sm={6}>
          
								<Grid container style={{margin:0}}>

									<Grid container justify="flex-start">

										<Grid item xs={12}>
											<div className={classes.titleContainer}>
												<h2 className={classes.titleSection}> {t('order_summary')} </h2>
											</div>
										</Grid>

									</Grid>

									<Grid item xs={12}>
										<div border={2} p={2} style={{
											background:"#FBFCFE",
											borderColor: "#5cc2c7"
										}}>
											<Grid container spacing={2}>
												<Grid item xs={12}>

													<Grid container spacing={2}>
														<Grid item xs={12}>
															<TableContainer style={{marginBottom:"24px"}}>
																<Table>
																	<TableHead>
																		<TableRow>
																			<TableCell className={classes.tableContent}>Name</TableCell>
																			<TableCell className={classes.tableContent}>Qty</TableCell>
																			<TableCell className={classes.tableContent}>Price</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{cart && cart.data.products.map((p, idx) =>
																			<TableRow
																				key={idx}
																			>
																				<TableCell className={classes.tableContent}>{p.data["Basic.Name"]}</TableCell>
																				<TableCell className={classes.tableContent}>{p.quantity}</TableCell>
																				<TableCell className={classes.tableContent} align="right">{p.data.Price.tax.display}</TableCell>
																			</TableRow>
																		)}
																		{cart && 
																		<>
																			<TableRow>
                                                  
																				<TableCell className={classes.tableContent} colSpan={2}>
																					{t('cart_subtotal')}
																				</TableCell>
																				<TableCell className={classes.tableContent} align="right">
																					{cart.data.subtotals.regular.display}
																				</TableCell>
																			</TableRow>
																			<TableRow>
																				<TableCell className={classes.tableContent} colSpan={2}>
																					{t('cart_tax')}
																				</TableCell>
																				<TableCell className={classes.tableContent} align="right">
																					{cart.data.total_vat_only.display}
																				</TableCell>
																			</TableRow>
																			<TableRow>
																				<TableCell className={classes.tableContent} colSpan={2}>
																					{t('cart_total')}
																				</TableCell>
																				<TableCell className={classes.tableContent} align="right">
																					{cart.data.total_vat.display}
																				</TableCell>
																			</TableRow>
																		</>
																		}
																	</TableBody>
                                            
																</Table>
															</TableContainer>
														</Grid>
													</Grid>
                            
												</Grid>
											</Grid>
										</div>
									</Grid>

								</Grid>
          
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									"padding":"20px 10px",
									"margin": "10px 0 20px",
									"borderTop": "1px solid rgba(224, 224, 224, 1)",
									"borderBottom": "1px solid rgba(224, 224, 224, 1)",
								}}
							>
								<Coupon
									cart={cart}
									addToCart={addToCart}
								/>
							</Grid>
							<Grid container justify="center">
								<Button className={classes.btn} style={{marginBottom:"12px",textAlign:"center"}} onClick={createLocation} type='submit'>
									{t("order_btn")}
								</Button>        
							</Grid>
						</Grid>                
					</Grid>
				</Grid>

			</div>
		</Grid>
	);
};
