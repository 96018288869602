import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

// css
import styles from "../SCSS/footer.module.scss";

export default () => {
	const { t } = useTranslation();
	return (
		<footer className={styles['footer']}>
			<div className={styles['footer-inner']}>
				<div className={styles['footer-wrapper']}>
					<ul>
						<li>
							<Link
								to="/page/terms"
							>{t("footer_terms_conditions")}</Link>
						</li>
						<li>
							<Link
								to="/page/privacy"
							>{t("footer_privacy_policy")}</Link>
						</li>
						<li>
							<Link
								to="/page/scta"
							>{t("footer_scta")}</Link>
						</li>
					</ul>
				</div>
				<div className={`${styles['footer-wrapper']} ${styles['footer-wrapper-right']}`}>
					<p>{t("footer_copyright", { year: moment().year() })}</p>
				</div>
			</div>
		</footer>
	);
}