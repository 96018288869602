import React from 'react';
import Login from './views/user/login';

import Index from './views/index';
import Profile from './views/profile/profile';
import Fanclub from './views/fanclub/fanclub';
import Contact from './views/contact/contact';
import Cart from './views/cart/cart';
import CartRedirect from './views/cart/cartRedirect';
import Checkout from './views/checkout/checkout';
import Account from './views/account/account';
import Order from './views/order/order';
import CmsPage from './views/cms/page';
import OrderCompleted from './views/order_completed/order_completed';
import OrderHistory from './views/order_history/order_history';
import OrderDetail from './views/order_history/order_detail';
import ForgottenPasswordMail from "./components/mails/Password/Forgotten/Mail";
import RecoverPasswordMail from "./components/mails/Password/Recover/Mail";
import AccountConfirmMail from "./components/mails/Account/Confirm/Mail";
import BillingFailedMail from "./components/mails/Order/Billing/Failed/Mail";
import BillingInvalidateMail from "./components/mails/Order/Billing/Invalidate/Mail";
import NewOrderMail from "./components/mails/Order/Created/Mail";
import BankTransfer from "./components/mails/Order/Transfer/Mail";
import BillPdf from "./components/mails/Order/Billing/Pdf/Pdf";
import Shipping from "./components/mails/Order/Shipping/Mail";
import ForgottenPassword from './views/forgottenPassword/forgottenPassword';
import RecoverPassword from './views/recoverPassword/recoverPassword';


import Layout from './common/layout';
//import Loading from './common/loading';
import { CssBaseline } from '@material-ui/core';
//import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

const Mail = () => {
	return <Switch>
		<Route exact path="/mail/mail_forgotten_pwd.mail">
			<ForgottenPasswordMail/>
		</Route>
		<Route exact path="/mail/mail_pwd_recovered.mail">
			<RecoverPasswordMail/>
		</Route>
		<Route exact path="/mail/account_confirm.mail">
			<AccountConfirmMail/>
		</Route>
		<Route exact path="/mail/order/billing_failed.mail">
			<BillingFailedMail/>
		</Route>
		<Route exact path="/mail/order/billing_method_invalidate.mail">
			<BillingInvalidateMail/>
		</Route>
		<Route exact path="/mail/order/order.mail">
			<NewOrderMail/>
		</Route>
		<Route exact path="/mail/payment_aozoranet.mail">
			<BankTransfer/>
		</Route>
		<Route exact path="/mail/order/bill.pdf">
			<BillPdf/>
		</Route>
		<Route exact path="/mail/order/shipping.mail">
			<Shipping/>
		</Route>
	</Switch>
}

const Pages = () => {
	return <Layout>
		<Switch>
			<Route exact path="/">
				<Index/>
			</Route>
			<Route exact path="/profile">
				<Profile/>
			</Route>
			<Route exact path="/cart">
				<Cart/>
			</Route>
			<Route exact path="/cart-redirect">
				<CartRedirect/>
			</Route>
			<Route exact path="/fanclub">
				<Fanclub/>
			</Route>
			<Route exact path="/contact">
				<Contact/>
			</Route>
			<Route exact path="/checkout">
				<Checkout/>
			</Route>
			<Route exact path="/order/:order">
				<Order/>
			</Route>
			<Route path="/page/">
				<CmsPage cms="@pages" base="page"/>
			</Route>
			<Route path="/login">
				<Login/>
			</Route>
			<Route exact path="/account">
				<Account/>
			</Route>
			<Route exact path="/order_completed">
				<OrderCompleted/>
			</Route>
			<Route exact path="/order_history">
				<OrderHistory/>
			</Route>
			<Route path="/order_history/:order">
				<OrderDetail/>
			</Route>
			<Route exact path="/forget-password">
				<ForgottenPassword/>
			</Route>
			<Route exact path="/recover-password/:login">
				<RecoverPassword/>
			</Route>
			<Route>
				<Redirect to=""/>
			</Route>
		</Switch>
	</Layout>
}


function Core() {
	// user is not logged in
	return (
		<Switch>
			<Route path="/mail">
				<Mail/>
			</Route>
			<Route>
				<Pages/>
			</Route>
		</Switch>
	);
}

function App() {
	return <SnackbarProvider><CssBaseline/><Core/></SnackbarProvider>
}

export default App;
