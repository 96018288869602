import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop,　Button, CircularProgress,　Grid, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from "@material-ui/core/TextField";
import { Get, rest } from "@karpeleslab/klbfw";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	title:{
		textAlign:"center",
		color: "#5cc2c7",
		paddingTop: '35px',
		paddingBottom: '15px',
		"@media (max-width: 825px)": {
			paddingTop: "20px",
			fontSize: "24px"
		},
	},
	btn:{
		color: "#ffffff",
		background: "#5cc2c7",
		'&:disabled': {
			color: "#ffffff",
			opacity:"0.4"
		},
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	},
	text:{
		textAlign:"center",
		margin: "0 0 15px"
	},
	container: {
		maxWidth: "825px",
		"@media (max-width: 825px)": {
			width: "100%"
		},
	},
	formContainer: {
		width: "100%",
		background: "#fff",
		padding: "20px",
		marginBottom: "20px"
	},
	linkBtn: {
		display: "inline-block",
		color: "#ffffff",
		background: "#5cc2c7",
		padding: "6px 8px",
		borderRadius: "4px",
		textDecoration: "none",
		'&:disabled': {
			color: "#ffffff",
			opacity:"0.4"
		},
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	}
}));


export default () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { login } = useParams();

	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		new_password: null,
		new_password_confirm: null,
	});
	const [sent, setSent] = useState(false);
	const [submitDisable, setSubmitDisable] = useState(true);
	const [showError, setShoeError] = useState(false);

	const formChange = (event) => {
		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;

		setForm({
			...form,
			...array
		});

	};

	const emailSubmit = (event) => {
		event.preventDefault();
		setLoading(true);

		const param = {
			login: login,
			key: Get('k'),
			p1: form.new_password,
			p2: form.new_password_confirm
		}

		// local check
		// setTimeout(() => {
		// 	setLoading(false);
		// 		setSent(true);
		// 	toast.error(t('recover-password-error'), {
		// 		position: "top-center",
		// 		autoClose: 3000,
		// 	});
		// }, 3000)

		rest("User:recover_password", "POST", param)
			.then(() => {
				setLoading(false);
				setSent(true);
			})
			.catch(() => {
				setLoading(false);
				toast.error(t('recover-password-error'), {
					position: "top-center",
					autoClose: 3000,
				});
			})
	}

	useEffect(() => {

		if (form.new_password !== null && form.new_password_confirm !== null && form.new_password === form.new_password_confirm) {
			setSubmitDisable(false);
			setShoeError(false);
		}
		else if (form.new_password !== null && form.new_password_confirm !== null && form.new_password !== form.new_password_confirm) {
			setSubmitDisable(true);
			setShoeError(true);
		}
		else {
			setSubmitDisable(true);
		}		

	}, [form, setSubmitDisable]);

	const buildProgress = () => {
		if (loading) {
			return (
				<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)
		} else {
			return null
		}
	}

	const buildContent = () => {
		if (sent) {
			return (
				<Grid item container spacing={2}>
					<div className={classes.formContainer}>
						<p className={classes.text}>{t('recover-password-comp')}</p>
						
						<Grid item xs={12} style={{textAlign: 'center'}}>
							<Link
								to="/login"
								className={classes.linkBtn}
							>{t('recover-password-login')}</Link>
						</Grid>
					</div>
				</Grid>
			)
		} else {
			return (
				<Grid item container spacing={2}>
					<div className={classes.formContainer}>
						<p className={classes.text} dangerouslySetInnerHTML={{__html : t('recover-password-text')}} />
						<form onSubmit={(e) => {emailSubmit(e)}}>
							
							<Grid item xs={12}>
								<TextField
									label={t("recover-password-new")}
									placeholder={t("recover-password-new")}
									name='new_password'
									type="password"
									required
									fullWidth
									value={form.new_password}
									onChange={e => formChange(e)}
									variant="outlined"
									disabled={loading}
									className={classes.text}
								/>
								<TextField
									label={t("recover-password-new-conf")}
									placeholder={t("recover-password-new-conf")}
									name='new_password_confirm'
									type="password"
									required
									fullWidth
									value={form.new_password_confirm}
									onChange={e => formChange(e)}
									variant="outlined"
									disabled={loading}
									className={classes.text}
								/>
								{showError && <p style={{margin: "0 0 15px", color: "red"}} >{t('recover-password-password-error')}</p>}
							</Grid>

							<Grid item xs={12} style={{textAlign: 'center'}}>
								<Button className={classes.btn} disabled={loading || submitDisable } type='submit'>
									{t("forget-password-submit")}
								</Button>
							</Grid>
						</form>
					</div>
				</Grid>
			)
		}
	}

	return (
		<Grid container className={classes.root} justify="center" spacing={2}>

			{buildProgress()}

			<div className={classes.container}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h2" className={classes.title}>{t('forget-password-title')}</Typography>
					</Grid>
				</Grid>

				{buildContent()}
			</div>
			<ToastContainer />
		</Grid>
	);
};
