import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Grid, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import commingSoon from '../../assets/img/img_comming_soon.png'

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	title:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
		paddingTop: '35px',
		paddingBottom: '40px',
		"@media (max-width: 825px)": {
			paddingTop: "20px",
			fontSize: "24px"
		},
	},
	comingSoon:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
	},
	comingSoonImg: {
		width: "100%",
		maxWidth: "400px",
		margin: "0 auto"
	},
	comingSoonWrapper: {
		textAlign: "center"
	}
}));



export default () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const {t} = useTranslation();

	useEffect(() => {
		setLoading(false)
	}, []);

	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			{loading?
				(<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>):null
			}
			<Grid item>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h2" className={classes.title}>{t('fanclub_title')}</Typography>
					</Grid>
					<Grid item xs={12} className={classes.comingSoonWrapper}>
						<img src={commingSoon} className={classes.comingSoonImg} alt="Comming Soon" />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
