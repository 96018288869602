import React, {Fragment} from 'react';
import Html from "./Html";
import Text from "./Text";
import Helmet from "react-helmet";
import { useTranslation } from 'react-i18next';

const Mail = () => {
	const { t } = useTranslation();
	return (
		<Fragment>
			<Helmet>
				<title>{t('mail_order_title')}</title>
				<meta name="mail-from-name" content={ t('mail_name') } />
				<meta name="mail-from" content="info@patraria.jp" />
			</Helmet>
			<Html/>
			<Text/>
		</Fragment>
	);
};

export default Mail;