import React from "react";
import Helmet from "react-helmet";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { useTranslation } from 'react-i18next';

import styles from "../SCSS/index.module.scss";

export default () => {
	const {t} = useTranslation();

	return (
		<div className={styles['index']}>
			<Helmet>
				<title>{ t('title_page_index') }</title>
			</Helmet>
			<TwitterTimelineEmbed
				sourceType="profile"
				screenName="patraria_rt"
				options={{height: 1200}}
			/>
			<div
				className={styles['btn']}
			>
			</div>
		</div>
	)
}
