import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Grid, Button, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from 'notistack';
import {rest} from "@karpeleslab/klbfw";


const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	paper: {
		maxWidth: 450,
		padding: theme.spacing(2),
	},
	title:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
		paddingTop: '35px',
		paddingBottom: '40px',
		"@media (max-width: 825px)": {
			paddingTop: "20px",
			fontSize: "24px"
		},
	},
	btn:{
		color: "#ffffff",
		background: "#5cc2c7",
		fontFamily:"Pomeranian",
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	},
	text:{
		color: "#5cc2c7",
		textAlign:"center",
		fontFamily:"Pomeranian",
	},
	container: {
		"@media (max-width: 825px)": {
			width: "90%"
		},
	},
}));



export default () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const {t} = useTranslation();
	const [sending, ] = useState(false);
	const [name,setName] = useState("");
	const [email,setEmail] = useState("");
	const [subject,setSubject] = useState("");
	const [message,setMessage] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const [messageSent,setMessageSent] = useState(false);
 
	useEffect(() => {
		setLoading(false)
	}, []);


	const submitHandler = (e) => {
		e.preventDefault();

		const query = {
			Email: email,
			Name: name,
			To: "@support",
			Subject: subject,
			Message: message,
		};


		rest('Support/Ticket', 'POST', query)
			.then(
				(data) => {
					setMessageSent(true)
				}
			)
			.catch(
				(data) => {
					enqueueSnackbar("Error when try to send ticket");
				}
			)
	
	
	  };
	
	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			{loading?
				(<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>):null
			}

			<div className={classes.container} >
			
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h2" className={classes.title}>{t('contact_title')}</Typography>
					</Grid>
				</Grid>

				<Grid item container spacing={2}>
					{!messageSent ? 
						<form onSubmit={submitHandler} style={{width:"100%"}}>
							<Grid container spacing={3} direction='column'>

								<Grid item xs={12}>
									<TextField
										label={t("contact_name")}
										placeholder={t("contact_name")}
										name='name'
										required
										fullWidth
										value={name}
										onChange={e => setName(e.target.value)}
										variant="outlined"
										disabled={sending}
										className={classes.text}
									/>
								</Grid>


								<Grid item xs={12} >
									<TextField
										label={t("contact_email")}
										placeholder={t("contact_email")}
										name='email'
										type='email'
										fullWidth
										value={email}
										onChange={e => setEmail(e.target.value)}
										variant="outlined"
										disabled={sending}
										required
										className={classes.text}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										label={t("contact_subject")}
										placeholder={t("contact_subject")}
										name='subject'
										fullWidth
										value={subject}
										onChange={e => setSubject(e.target.value)}
										variant="outlined"
										disabled={sending}
										required
										className={classes.text}
									/>
								</Grid>


								<Grid item xs={12}>
									<TextField
										label={t("contact_message")}
										placeholder={t("contact_message")}
										name='content'
										fullWidth
										value={message}
										onChange={e => setMessage(e.target.value)}
										variant="outlined"
										disabled={sending}
										required
										multiline
										rows={5}
										rowsMax={Infinity}
										className={classes.text}
									/>
								</Grid>

								<Grid item xs={12} style={{textAlign: 'center', paddingTop: '22px', paddingBottom: '40px'}}>
									{sending && <CircularProgress/>}
									{!sending &&
									<Button className={classes.btn} style={{marginRight:"24px",marginBottom:"12px"}} disabled={sending} type='submit'>
										{t("contact_submit")}
									</Button>}
								</Grid>


							</Grid>
						</form>
						:
						<div>
							<p className={classes.text}>{t("message_sent_p")}</p>
						</div>
					}
				</Grid>
				
			</div>
		</Grid>
	);
};
