import React, {useState, useEffect, useReducer} from "react";
import {rest, Get} from "@karpeleslab/klbfw";
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Grid, Paper, Button, FormControl, Input, InputLabel, TextField, Typography, InputAdornment, IconButton, FormControlLabel, Checkbox, Link} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import { Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	paper: {
		maxWidth: 450,
		padding: theme.spacing(2),
	},
	title:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
		paddingTop: '35px',
		paddingBottom: '40px',
		"@media (max-width: 825px)": {
			paddingTop: "20px",
			fontSize: "24px"
		}
	},
}));

function LoginOAuth2({button, info, trigger}) {
	return <Button variant="contained"
		style={{
			backgroundImage: `url(${button.logo})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			backgroundSize: '70% 70%',
			borderRadius: 50,
			backgroundColor: button['background-color']
		}}
		onClick={() => trigger({type: "post", oauth2: info.OAuth2_Consumer__})}
	>&nbsp;</Button>;
}

function PasswordField(props) {
	const [show, setShow] = useState(false);
	let res = [];

	const id = "password-input-"+props.name;

	if (props.label) {
		res.push(<InputLabel key={0} htmlFor={id}>{props.label}</InputLabel>);
	}

	res.push(<Input
		key={1} id={id}
		type={show?'text':'password'}
		endAdornment={
			<InputAdornment position="end">
				<IconButton aria-label="toggle password visibility" onMouseDown={e => e.preventDefault()} onClick={() => setShow(!show)}>
					{show ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			</InputAdornment>
		}
		{...props}
	/>);

	return <FormControl fullWidth>{res}</FormControl>;
}

function LoginField(field) {
	const {type, trigger} = field;

	switch(type) {
	case "label":
		let labelTxt = field.label;
		if (field.style === "error") {
			if (field.link) {
				labelTxt = <Link href={field.link} target="_blank" rel="noopener" color="inherit">{labelTxt}</Link>;
			}
			return <MuiAlert severity="error" elevation={6} variant="filled">{labelTxt}</MuiAlert>;
		}
		if (field.link) {
			labelTxt = <Link href={field.link} target="_blank" rel="noopener">{labelTxt}</Link>;
		}
		return <Grid item xs={12}><Typography>{labelTxt}</Typography></Grid>;
	case "text":
		return <Grid item xs={12}><TextField label={field.label} onChange={e => trigger({type: "set", [field.name]: e.target.value})} fullWidth/></Grid>;
	case "email":
		return <Grid item xs={12}><TextField label={field.label} onChange={e => trigger({type: "set", [field.name]: e.target.value})} fullWidth/></Grid>;
	case "checkbox":
		let checkboxLabel = field.label;
		if (field.link) checkboxLabel = <Link href={field.link} target="_blank" rel="noopener">{checkboxLabel}</Link>;
		return <Grid item xs={12}><FormControlLabel control={<Checkbox color="primary" onChange={e => trigger({type: "set", [field.name]: e.target.checked?"1":undefined})}/>} label={checkboxLabel}/></Grid>;
	case "password":
		return <Grid item xs={12}><PasswordField label={field.label} name={field.name} onChange={e => trigger({type: "set", [field.name]: e.target.value})} fullWidth/></Grid>;
	case "oauth2":
		return <Grid item xs={4}><LoginOAuth2 {...field}/></Grid>;
	default:
		return null;
	}
}

export default () => {
	// login module, using rest POST on User:flow
	const [session, setSession] = useState(null);
	const [flow, updateFlow] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [user, setUser] = useRest("User:get");
	const classes = useStyles();
	const {t} = useTranslation();
	const history = useHistory();

	const [form, dispatch] = useReducer((state, action) => {
		switch(action.type) {
		case 'set':
			return {...state, ...action, type: undefined};
		case 'clear':
			return {};
		default:
			return state;
		}
	}, {});

	function trigger(action) {
		
		function handleResponse(res) {
			
			if (res.data.url) {
				window.location.href = res.data.url;
				return;
			}
			if (res.data.complete) {
				setUser({data: res.data.user});
				// TODO: handle res.data.Redirect ?
				//history.push(res.data.Redirect+history.search)
				return;
			}
			if (res.data.session) setSession(res.data.session);
			updateFlow(res);
			setError(null);
			dispatch({type: "clear"});
			setLoading(false);
		}

		function handleError(res) {
			setError(res.message);
			setLoading(false);
		}

		switch(action.type) {
		case 'submit':
			setLoading(true);
			rest("User:flow", "POST", {...form, session: session}).then(handleResponse).catch(handleError);
			return;
		case 'post':
			setLoading(true);
			rest("User:flow", "POST", {session: session, ...action, type: undefined}).then(handleResponse).catch(handleError);
			return;
		case 'reset':
			setLoading(true);
			rest("User:flow", "POST", {}).then(handleResponse).catch(handleError);
			return;
		default:
			dispatch(action);
		}
	}

	useEffect(() => {
		if (Get("session")) {
			trigger({type: "post", session: Get("session")});
			return;
		}
		trigger({type: "post"});
		// eslint-disable-next-line
	}, []);

	if ((user) && (user.data)) {
		return history.location.search ? <Redirect to="/checkout/"/> : <Redirect to="/"/>
	}

	if (flow === null) {
		return (<Backdrop className={classes.backdrop} open={true}>
			<CircularProgress color="inherit" />
		</Backdrop>);
	}

	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			{loading?
				(<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>):null
			}
			<div className={classes.container} >
			
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h2" className={classes.title}>{flow.data.message}</Typography>
					</Grid>
				</Grid>


				<Grid item>
					<Paper className={classes.paper}>
						<Grid container spacing={2}>
							{error && <Grid item xs={12}><MuiAlert severity="error" elevation={6} variant="filled">{error}</MuiAlert></Grid>}
							<Grid container item xs={12} spacing={2}>
								{flow.data.fields.map((field, idx) => (
									<LoginField key={idx} form={form} trigger={trigger} {...field}/>
								))}
							</Grid>
							<Grid item container justify="flex-end" spacing={2}>
								{!flow.data.initial && <Grid item><Button variant="contained" color="secondary" onClick={() => trigger({type: "reset"})}>{t("user_login_reset_btn")}</Button></Grid>}
								<Grid item><Button variant="contained" color="primary" onClick={() => trigger({type: "submit"})}>{t("user_login_submit_btn")}</Button></Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</div>
		</Grid>
	);
};
