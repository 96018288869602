import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import bgname from '../../assets/img/bgName.png'

import {rest} from "@karpeleslab/klbfw";
import {useRest} from '@karpeleslab/react-klbfw-hooks';

//import NumericInput from 'react-numeric-input';
import { useSnackbar } from 'notistack';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// component
import Coupon from "../../components/coupon/coupon";

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	container:{
		width:"840px",
		padding:"16px",
		background:'#ffffff',
		"@media (min-width: 576px)": {
			maxWidth: "540px"
		},
		"@media (min-width: 768px)": {
			maxWidth: "720px"
		},
		"@media (min-width: 992px)": {
			maxWidth: "840px"
		},
		"@media (min-width: 1200px)": {
			maxWidth: "840px"
		}
	},
	title:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
	},
	bgname:{
		height: "82px",
		fontSize: "61px",
		backgroundImage: `url(${bgname})`,
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		textAlign: 'center',
		color:'#ffffff',
		backgroundRepeat:'no-repeat',
		fontWeight:'bolder',
		fontFamily:"Pomeranian",
		marginTop: "-48px"
	},
	head: {
		width:"100px"
	},
	containerTitle:{
		border : "solid 3px #85cad1",
		marginTop: "32px"
	},
	text:{
		color: "#5cc2c7",
		textAlign:"center",
		fontFamily:"Pomeranian",
		fontSize: "32px",
	},
	productName:{
		color: "#5cc2c7",
		textAlign:"left",
		fontFamily:"Pomeranian",
		fontSize: "20px",
		margin: "6px",
	},
	productPrice:{
		color: "#5cc2c7",
		textAlign:"left",
		fontFamily:"Pomeranian",
		fontSize: "18px",
		margin: "16px 6px",
		fontWeight:"bold",
	},
	product:{
		borderBottom: "solid 1px #5cc2c7",
		paddingBottom: "12px",
	},
	btn:{
		color: "#ffffff",
		background: "#5cc2c7",
		fontFamily:"Pomeranian",
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	},
	qty:{
		color: "#5cc2c7",
		fontWeight:"bold",
		fontFamily:"Pomeranian",
	},
	cart:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	},
	tableContent:{
		color: "#5cc2c7",
		fontFamily:"Pomeranian",
	}
}));

function useGetCart(){
	return useRest("Catalog/Cart/@");
}

export default () => {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const {t} = useTranslation();
	const [cart, cartRefresh] = useGetCart();
	const location = useLocation();
	const history = useHistory();
	const hash = qs.parse(location.search.split('?')[1]);

	const removeFromCart = (keyProduct,name) => {
		const query = {
			key: keyProduct,
			quantity:0
		};

		rest('Catalog/Cart/@:setQuantity', 'POST', query)
			.then(
				(data) => {
					enqueueSnackbar(name+' remove from in cart');
					cartRefresh(data)
				}
			)
			.catch(
				(data) => {
					enqueueSnackbar(data);
				}
			)

	}

	const buildBtn = () => {
		if (cart.data.products.length > 0) {
			return (
				<Link to="/checkout" style={{textDecoration:"none"}}>
					<Button className={classes.btn}>
						{t("go_checkout_btn")}
					</Button>
				</Link>
			)
		}
		else {
			return (
				<Link to="/goods" style={{textDecoration:"none"}}>
					<Button className={classes.btn}>
						{t("go_goodst_btn")}
					</Button>
				</Link>
			)
		}
	}

	const addToCart = (hash) => {

		if ( hash === void 0 ) {
			return false;
		}

		const query = {
			request: hash,
		};

		console.log(hash)

		rest('Catalog/Cart/@:process', 'POST', query)
			.then(
				() => {
					cartRefresh();
					history.push(`/cart-redirect`);
				}
			)
			.catch(
				(data) => {
					enqueueSnackbar(data.error);
				}
			)
	};

	useEffect(() => {
		addToCart(hash.product);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	const buildCart = () => {
		if (cart === null) return false;

		if (cart.data.products.length === 0) {
			return (
				<>
					<Grid container spacing={2}>
						<Grid
							item
							xs={12}
							style={{
								textAlign: "center"
							}}
						>
							{t('cart_no_item')}
						</Grid>
					</Grid>
														
					<Grid container spacing={2}
						justify="center"
						style={{
							textAlign: "center",
							padding: "20px 0"
						}}
					>
						{buildBtn()}
					</Grid>
				</>
			)
		}
		else {
			return (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TableContainer style={{marginBottom:"24px"}}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell className={classes.tableContent}>{t('order_product_name')}</TableCell>
											<TableCell className={classes.tableContent}>{t('quantity_lbl')}</TableCell>
											<TableCell className={classes.tableContent}>{t('order_price')}</TableCell>
											<TableCell className={classes.tableContent}>{t('order_remove')}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{cart && cart.data.products.map((p) =>
											<TableRow>
												<TableCell className={classes.tableContent}>{p.data["Basic.Name"]}</TableCell>
												<TableCell className={classes.tableContent}>{p.quantity}</TableCell>
												<TableCell className={classes.tableContent}>{p.data.Price.tax.display}</TableCell>
												<TableCell className={classes.tableContent}><span onClick={e => removeFromCart(p.key,p.data["Basic.Name"])}>X</span></TableCell>
											</TableRow>
										)}
										{cart && 
										<>
											<TableRow>
												<TableCell rowSpan={4} />
												<TableCell className={classes.tableContent} colSpan={2}>
													{t('cart_subtotal')}
												</TableCell>
												<TableCell className={classes.tableContent} align="right">
													{cart.data.subtotals.regular.display}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className={classes.tableContent} colSpan={2}>
													{t('cart_tax')}
												</TableCell>
												<TableCell className={classes.tableContent} align="right">
													{cart.data.total_vat_only.display}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className={classes.tableContent} colSpan={2}>
													{t('cart_total')}
												</TableCell>
												<TableCell className={classes.tableContent} align="right">
													{cart.data.total_vat.display}
												</TableCell>
											</TableRow>
										</>
										}
									</TableBody>
																					
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
					
					<Grid
						item
						xs={12}
						style={{
							borderBottom: "1px solid rgba(224, 224, 224, 1",
							padding: "0 0 16px"
						}}
					>
						<Coupon
							cart={cart}
							addToCart={addToCart}
						/>
					</Grid>
													
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TableContainer style={{marginBottom:"24px"}}>
								<Table>
									<TableBody>
										{cart && 
										<>
											<TableRow>
												<TableCell className={classes.tableContent} colSpan={4} align="right">
													{buildBtn()}
												</TableCell>
											</TableRow>
										</>
										}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				</>
			)
		}
	}


	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			<div className={classes.container} >
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<p className={classes.cart}>{t("my_cart_title")}</p>
						{buildCart()}
					</Grid>
				</Grid>
			</div>
		</Grid>
	);
};
