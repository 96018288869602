import { useRest } from '@karpeleslab/react-klbfw-hooks';
import {useHistory} from "react-router-dom"

export default () => {

	const [user] = useRest("User:get");
	const history = useHistory();

	if (user === null) return false;

	if (user.data === null) {
		history.push("/");
	}

}