import React from "react";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import isLoggedIn from '../../helpers/is_logged_in.js';

// style
import styles from "../../SCSS/history.module.scss";


export default () => {
	const { t } = useTranslation();
	const [orderHistory] = useRest("Order", "GET", {sort : {Date : 'ASC'}});
		
	isLoggedIn();


	const buildLink = (item) => {
		return (
			<Link to={`/order_history/${item.Order__}`} style={{textDecoration:'none'}}>{t('show_order')}</Link>
		)
	}

	const buildInvoiceNum = (num) => {
		if (num === null) {
			return "-";
		}
		else {
			return num;
		}

	}



	const buildList = () => {
		if (orderHistory === null) {
			return null
		}
		else if (orderHistory.data.length === 0) {
			return (
				<tr>
					<td colSpan="5">情報がありません</td>
				</tr>
			)
		}
		else {
			return orderHistory.data.map((item, idx) => {
				return (
					<tr
						key={idx}
						className={classNames(
							{
								[styles['list-item-comp']]: item.Status === "completed"
							}
						)}
					>
						<td>{buildInvoiceNum(item.Invoice_Number)}</td>
						<td>{item.Total_Vat.display}</td>
						<td>{t(`status_${item.Status ?? 'unknown'}`)}</td>
						<td>
							{buildLink(item)}
						</td>
					</tr>
				)
			})
		}
	}

	return (
		<div>
			<h2 className={styles['title']}>{t('order_history_title')}</h2>
			<div className={styles['history-wrapper']}>
				<div className={styles['history']}>
					<div className={styles['history-inner']}>
						<table cellSpacing="0">
							<thead>
								<tr>
									<th>{(t('order_invoice_number'))}</th>
									<th>{(t('order_price'))}</th>
									<th>{(t('order_status'))}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{buildList()}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};
