import React, {useState, useEffect} from "react";
import {Backdrop, CircularProgress, } from '@material-ui/core';
import patra from '../../assets/img/img_profile_patra.png';
import ria from '../../assets/img/img_profile_ria.png';
import patrac from '../../assets/img/patra_color.png';
import riac from '../../assets/img/ria_color.png';

import twitter from '../../assets/img/twitter.png';
import youtube from '../../assets/img/youtube.png';
import insta from '../../assets/img/insta.png';

import {useRest} from '@karpeleslab/react-klbfw-hooks';
import { useTranslation } from "react-i18next";


import styles from "../../SCSS/profile.module.scss";


const useMembersList = () => {
	return useRest("Music/Label/Artist/@artist/Member", "GET");
}


export default () => {
	const [loading, setLoading] = useState(true);

	const {t} = useTranslation();
	const [members] = useMembersList();

	useEffect(() => {
		setLoading(false)
        
	}, []);

	const buildLoading = () => {
		if (loading) {
			return (
				<Backdrop className={styles['profile-loading']} open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)
		} else {
			return null
		}
	}

	const buildImg = (member) => {
		switch(member.Code) {
		case 'patra':
			return (
				<div
					className={styles['profile-item-avater']}
				>
					<img src={patra} alt="Patra" />
				</div>
			)
		case 'ria':
			return (
				<div
					className={styles['profile-item-avater']}
				>
					<img src={ria} alt="Ria" />
				</div>
			)
		default:
			return null;
		}
		
	}

	const buildLink = (link) => {
		let limg = null;
		let altName = link.Type;

		switch(link.Type) {
		case 'twitter':
			limg = twitter;
			altName = 'Twitter';
			break;
		case 'youtube':
			limg = youtube;
			altName = 'Youtube';
			break;
		case 'instagram':
			limg = insta;
			altName = 'Instagram';
			break;
		default:
			break;
		}

		return <a href={link.Link} target="_blank" rel="noopener noreferrer"><img src={limg} className={styles['profile-item-sns']} alt={altName}/></a>;
	}

	const buildColor = (member) => {
		switch(member.Code) {
		case 'patra':
			return <p>{t('color_p')}: <img src={patrac} className={styles['profile-item-heart']} alt="Patra's favorite color" /></p>
		case 'ria':
			return <p>{t('color_p')}: <img src={riac} className={styles['profile-item-heart']} alt="Ria's favorite color" /></p>
		default:
			return null;
		}
		
	}

	const buildName = (member) => {
		return member.Name+"/"+member.Code;
	}


	const buildMemberList = () => {

		if (members === null) return false;

		return members.data.map((member) => {
			return (
				<div
					key={member.Code}
					className={styles['profile-item']}>
					{buildImg(member)}
					<div
						className={styles['profile-item-name']}>
						{buildName(member)}
					</div>
					<div
						className={styles['profile-item-bio']}>
						{member.Bio}
					</div>
					<div
						className={styles['profile-item-birth']}>
						{member.Birthdate.substr(5,5).replace('-','/')}
					</div>
					<div
						className={styles['profile-item-color']}>
						{buildColor(member)}
					</div>
					{member.Links.map((link, index) =>
						<div key={member.Code+index}>
							{buildLink(link)}
						</div>
					)}
				</div>
			)
		})
		
	}


	return (

		<div
			className={styles['profile-wrapper']}
		>

			{buildLoading()}

			<div className={styles['profile-list']}>
				{buildMemberList()}
			</div>

		</div>
	);
};
