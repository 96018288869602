import React, {useState} from "react";
import {rest} from '@karpeleslab/klbfw'


// style
import styles from "../../SCSS/account.module.scss";
import formStyles from '../../SCSS/login.module.scss';


const Info = (props) => {

	const {user, refresh} = props;
	const [userInfo, setUesrInfo] = useState({
		name: user.data.Profile.Display_Name === null? "":user.data.Profile.Display_Name,
		mail: user.data.Email,
		pwd: ""
	});
	const [modal, setModal] = useState(false);

	const onInputChange = (e) => {
		setUesrInfo({
			...userInfo,
			[e.target.getAttribute('name')]: e.target.value
		})
	}

	const modalHandler = (val) => {
		setModal(val);
	}

	const onSubmitHandler = (e) => {
		e.preventDefault();
		if ( userInfo.mail !== user.data.Email) {
			if ( user.data.hasPassword !== false ) {
				modalHandler(true);
			} else {
				changeInfo(userInfo.name, userInfo.mail)
			}
		} else {
			changeInfo(userInfo.name, userInfo.mail)
		}
	}

	const onEmailChangeHandler = (e) => {
		e.preventDefault();
		setModal(false);
		changeInfo(userInfo.name, userInfo.mail, userInfo.pwd)
	}

	const buildModal = () => {
		return (
			modal && <div className={styles['profile-modal']}
			>
				<div className={styles['profile-modal-inner']}
				>
					<h3>認証が必要です。</h3>
					<p>この操作を行うためにパスワードを確認して下さい。</p>
					<form onSubmit={onEmailChangeHandler}>
						<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`}>
							<label htmlFor='change-info-pwd'>パスワード</label>
							<input type='password' id='change-info-pwd' name='pwd' required onChange={onInputChange} autoComplete="off"/>
						</div>
						<div className={formStyles['form-btn-group']}>
							<button type="submit"
								className={`${formStyles['form-btn']} ${formStyles['form-btn--small']}  ${formStyles['form-btn--save']}`}
							>保存</button>
							<button type="button"
								className={`${formStyles['form-btn']} ${formStyles['form-btn--small']}`}
								onClick={() => {modalHandler(false)}}
							>キャンセル</button>
						</div>
					</form>
				</div>
			</div>
		)
	}

	const changeInfo = (name, email, password) => {
		if (name !== user.data.Profile.Display_Name) {
			rest('User/@/Profile', 'PATCH', {Display_Name: name})
				.then(
					() => {
						refresh()
					}
				)
		}

		if (email !== user.data.Email) {
			let data = {
				'email': email
			}
			if (password) {
				data.current_password = password
			}
			rest('User/@:setEmail', 'POST', data)
				.then(
					() => {
						refresh()
					}
				)
		}
	};

	const handleBtn = () => {
		const nameFlg = userInfo.name.length > 0 && user.data.Profile.Display_Name !== userInfo.name;
		const mailFlg = userInfo.mail.length > 0 && user.data.Email !== userInfo.mail;
		return nameFlg || mailFlg;
	}

	return (
		<>
			<div className={styles['profile-info']} >
				<form onSubmit={onSubmitHandler}>
					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`} >
						<label htmlFor='name'>お名前</label>
						<input required id='name' type='text' name='name' value={userInfo.name} onChange={onInputChange} />
					</div>

					<div className={`${formStyles['form-field']} ${formStyles['form-field--input']}`} >
						<label htmlFor='mail'>メールアドレス</label>
						<input required id='mail' type='email' name='mail' value={userInfo.mail} onChange={onInputChange} />
					</div>
					<div className={formStyles['form-btn-wrapper']}>
						<button type="submit"
							className={`${formStyles['form-btn']} ${formStyles['form-btn--small']}`}
							disabled={!handleBtn()}
						>変更</button>
					</div>
				</form>
			</div>
			{buildModal()}
		</>
	)
}

export default Info;