import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Grid, Typography,Button} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";


const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		flexGrow: 1,
	},
	title:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
	},
	content:{
		fontFamily:"Pomeranian",
		textAlign:"center",
		color: "#5cc2c7",
		lineHeight: "32px",
		whiteSpace: "pre-line",
	},
	btn:{
		color: "#ffffff",
		background: "#5cc2c7",
		fontFamily:"Pomeranian",
		'&:hover':{
			color: "#ffffff",
			background: "#5cc2c7",
			opacity:"0.8"
		}
	},
}));



export default () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const {t} = useTranslation();

	useEffect(() => {
		setLoading(false)
	}, []);
	
	return (
		<Grid container className={classes.root} justify="center" spacing={2}>
			{loading?
				(<Backdrop className={classes.backdrop} open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>):null
			}
			<Grid item>
				
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h2" className={classes.title}>{t('order_completed_title')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.content}>{t('order_completed_p')}</p>
					</Grid>
					<Grid item xs={12}>
						<div style={{textAlign:"center"}}>
							<Link to="/order_history" style={{textDecoration:"none"}}>
								<Button className={classes.btn}>
									{t("show_order_history_btn")}
								</Button>
							</Link>
						</div>
					</Grid>
				</Grid>
				
			</Grid>
		</Grid>
	);
};
