import React from 'react';
import {Get} from "@karpeleslab/klbfw";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();
    
	return (
		<div id="mail-text">
			{t('mail_user_name', {name:`${Get('_order').Billing_User_Location.Last_Name} ${Get('_order').Billing_User_Location.First_Name}`})}

			{t('mail_order_thanks')}
			{t('mail_order_complete_text')}


■{t('mail_order_details')}
			{t('mail_subtotal')}:{Get('_order').Total.display}
			{t('mail_discount')}:{Get('_order').Subtotals.discount.display}
			{t('mail_shipping_fee')}:{Get('_order').Subtotals.shipping.display}
			{t('mail_tax')}:{Get('_order').Vat_Amount.display}
			{t('mail_total')}:{Get('_order').Total_Vat.display}


■{ t('mail_bank_transfer_label') }
			{ t('mail_bank_transfer_text', {order: Get('_order').Meta, expires: moment(Get('_order').Meta.AozoraNet_Expires).format('YYYY/MM/DD HH:mm:ss')}) }

■{t('mail_download_sub')}
			{t('mail_download_text_1')}
			{t('mail_download_text_2')}
https://patraria.jp/order_history

■{t('mail_contact')}
https://patraria.jp/contact

https://patraria.jp/

		</div>
	);
};

export default Text;