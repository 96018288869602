import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// style
import styles from "../../SCSS/coupon.module.scss";


const Coupon = (props) => {

	const { addToCart, cart } = props;
	const [couponCode, setCouponCode] = useState('');
	const { t } = useTranslation();

	if (cart === null ||cart.data.products.length === 0) {
		return null
	}
	else {
		return (
			<>
				<div
					className={styles['coupon-title-wrapper']}
				>
					<h3
						className={styles['coupon-title']}
					>{t('cart_coupon_title')}</h3>
					<p
						className={styles['coupon-title-info']}
					>{t('cart_coupon_text')}</p>
				</div>
				<div >
					<form
						onSubmit={(e) => {
							e.preventDefault();
							addToCart(`coupon,coupon-code=${encodeURIComponent(couponCode)}`)
						}}
					>
						<ul
							className={styles['coupon']}
						>
							<li
								style={{
									fontSize: "1rem"
								}}
							>
								<input
									className={styles['coupon-input']}
									type="text"
									value={couponCode}
									onChange={(e) => {setCouponCode(e.target.value)}}
								/>
							</li>
							<li>
								<div
									className={styles['coupon-btn-warapper']}
								>
									<button
										className={styles['coupon-btn-glay']}
										disabled={ couponCode.length === 0 ? true : false }
										type="submit"
									>{t('cart_coupon_submit')}</button>
								</div>
							</li>
						</ul>
					</form>
				</div>
			</>
		);

	}
}

export default Coupon;