import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useLocation} from "react-router-dom";
import classNames from 'classnames';

import Header from './Header.js';

import Footer from "./footer"

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: 'transparent',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingTop: '410px',
		"@media (max-width: 825px)": {
			paddingTop: "120px"
		},
	},
	toolbar: theme.mixins.toolbar,
	menuLink:{
		textDecoration: 'none',
		color:'#5cc2c7',
		"&:hover,&:focus": {
			opacity:'0.8',
			color:'#85cad1',
			
		},
	},
	profile: {
		paddingLeft: "0",
		paddingRight: "0",
		paddingBottom: "0",
		paddingTop: "375px",
		backgroundColor: "#f3f4f6",
		"@media (max-width: 825px)": {
			paddingTop: "120px"
		},
	}
}));

export default ({children}) => {
	const classes = useStyles();
	const page = useLocation();

	return (

		<div className={classes.root}>

			<Header />
			<main className={classNames(`${classes.content}`,
				{
					[classes.profile]: page.pathname === "/profile"
				}
			)}>
				<div className={classes.toolbar}>
					{children}
				</div>
			</main>
			<Footer />
		</div>
	);
};
